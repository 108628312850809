import React, { useState, useEffect, useRef } from "react";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../assets/images/vikatan-play-logo.png";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";
import AudioMagazinePlayer from "../../pages/story/audiomagazine-story";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link, WithHostUrl } from "@quintype/components";
import { LoadingIndicatorComponent } from "../../atoms/loading-indicator";
import { DeepBI } from "../../../integrations";
import MobileLogin from "../header/user-login/login-mobile";
import { fetchAudioSearch } from "../../../api";
import spinner from "../../../../assets/images/spinner.svg";

const VikatanAudioMagazineHeader = user => {
  const [isAudioMenuVisible, setIsAudioMenuVisible] = useState(false);
  const [userType, setUserType] = useState("F");
  const [userEmail, setuserEmail] = useState("");
  // const [userName, setuserName] = useState("");
  // const [userJoinDate, setuserJoinDate] = useState("");
  const [loggedOut, setloggedOut] = useState(true);
  const [IsOpened, setIsOpened] = useState(false);
  const domainName = global && global.location && global.location.origin;

  const menuRef = useRef(null);
  const searchInputRef = useRef(null);
  const debounceTimerRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  // const [isFocused, setIsFocused] = useState(false);

  // Initialize from URL on mount
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const q = queryParams.get("q");
    if (q) {
      setSearchQuery(q);
      if (window.location.pathname === "/vikatan-play-search" && q.trim().length >= 3) {
        setIsSearching(true);
        handleSearch(q);
      }
    }

    // Focus if on search page
    const pageslug = global && global.location && global.location.pathname;
    if (pageslug === "/vikatan-play-search") {
      searchInputRef && searchInputRef.current && searchInputRef.current.focus();
    }

    // Cleanup
    return () => {
      if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);
    };
  }, []);

  useEffect(() => {
    const pageslug = global && global.location && global.location.pathname;

    if (pageslug === "/vikatan-play-search") {
      searchInputRef && searchInputRef.current && searchInputRef.current.focus();
    }
  }, []);

  const handleClick = e => {
    e.stopPropagation();
    DeepBI.PingVikatanplaySearch("audiosearchclick");
  };

  const handleSearchChange = event => {
    const value = event.target.value;
    setSearchQuery(value);

    // Clear previous timer
    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);

    // Set new debounce timer
    debounceTimerRef.current = setTimeout(() => {
      const pageslug = global && global.location && global.location.pathname;
      const isSearchPage = pageslug === "/vikatan-play-search";
      const newUrl = `/vikatan-play-search?q=${encodeURIComponent(value)}`;

      // Redirect if not on search page (regardless of query length)
      if (!isSearchPage) {
        global.location.href = newUrl;
      }
      // On search page: update URL and handle search
      else {
        window.history.replaceState({}, "", newUrl);

        if (value.trim().length >= 3) {
          setIsSearching(true);
          handleSearch(value);
        } else {
          setSearchResults([]);
          setIsSearching(false);
        }
      }
    }, 500);
  };

  const handleSearch = query => {
    setLoading(true);
    setIsSearching(query.length > 0);
    fetchAudioSearch(query, 30, 0)
      .then(response => {
        const results = response.audioSearchList || [];
        setSearchResults(results);

        if (results.length === 0) {
          DeepBI.PingVikatanplaySearch("audiosearchnoresults", {}, query);
        }
      })
      .catch(error => {
        console.error("Error fetching search results:", error);
        setSearchResults([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClearSearch = event => {
    const pageslug = global && global.location && global.location.pathname;

    if (pageslug === "/vikatan-play-search") {
      const newUrl = `${pageslug}?q=${encodeURIComponent("")}`;
      window.history.replaceState({}, "", newUrl);
    }
    setSearchQuery("");
    setSearchResults([""]);
    setIsSearching(false);
    setLoading(false);
    event.stopPropagation();
  };

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(get(user, ["userType"]));
      user && setuserEmail(get(user, ["userEmail"]));
      user && setuserEmail(get(user, ["userEmail"]));
      //   user && setuserName(get(user, ["username"]));
      //  user && setuserJoinDate(get(user, ["userJoinDate"]));
      user && setloggedOut(get(user, ["loggedOut"]));
    });
  }, []);

  useEffect(
    () => {
      const handleClickOutside = event => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsAudioMenuVisible(false); // Close the menu if clicked outside
          // setIsSearching(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    },
    [menuRef]
  );

  const toggleMenu = () => {
    setIsAudioMenuVisible(!isAudioMenuVisible);
  };

  const loginOptionPopup = () => {
    DeepBI.PingLoginevents("Login_widget_view");
    if (global.webengage && global.webengage.track) {
      global.webengage.track("Login_widget_view", {});
    }
    setIsOpened(prevIsOpened => !prevIsOpened);
    setIsAudioMenuVisible(false);
  };

  const handleMobileLoginClose = isPopupClosed => {
    setIsOpened(isPopupClosed);
  };

  const handleNavigation = result => {
    setIsSearching(false);
    setSearchQuery("");
    setSearchResults([""]);
    DeepBI.PingVikatanplaySearch("audiosearchresultclick", {}, searchQuery, result);
  };

  return (
    <div className={styles["audio-header"]}>
      <AudioMagazinePlayer />
      <LoadingIndicatorComponent />
      <div className={styles["audio-header-conatiner"]}>
        <div className={styles["audio-header-grid"]}>
          <div className={styles["menu-logo"]}>
            <div className={styles["hamburger"]} onClick={toggleMenu}>
              <span />
              <span />
              <span />
            </div>

            <WithHostUrl>
              {({ primaryHostUrl }) => (
                <Link
                  className={styles["home-link"]}
                  aria-label="Vikatan"
                  href={primaryHostUrl ? primaryHostUrl + "/vikatan-play" + "/" : "/"}
                >
                  <img
                    alt="Vikatan"
                    src={assetify(logo)}
                    className={styles["brand-logo"]}
                    width="104px"
                    height="65px"
                  />
                  {/* <img
                    alt="Vikatan"
                    src={assetify(logo)}
                    className={styles["brand-logo-icon"]}
                    width="40px"
                    height="50px"
                  /> */}
                </Link>
              )}
            </WithHostUrl>
            {/* <a className={styles["home-link"]} aria-label="Vikatan" href="https://www.vikatan.com/vikatan-play">
              <img alt="Vikatan" src={assetify(logo)} className={styles["brand-logo"]} width="240px" height="50px" />
            </a> */}
          </div>

          <div className={styles["audio-search-bar-wrap"]}>
            <div className={styles["audio-search-bar"]}>
              <input
                type="search"
                value={searchQuery}
                onChange={handleSearchChange}
                onClick={handleClick} // Only for click tracking
                ref={searchInputRef}
                placeholder="Search audiobook"
              />
              <div onClick={handleClearSearch} className={styles[isSearching ? "close-icon" : "search-icon"]}>
                <Icon type={isSearching ? "close" : "search"} />
              </div>

              {/* {isSearching ? (
                <div
                  onClick={e => {
                    console.log("Div clicked"); // Debugging
                    handleClearSearch(e);
                  }}
                  className={styles["close-icon"]}
                >
                  <Icon type="close" />
                </div>
              ) : (
                <div className={styles["search-icon"]}>
                  <Icon type="search" />
                </div>
              )} */}
              {/* {loading ? (
                <div className={styles["popup-wrapper"]}>
                  <div className={styles["popup-wrapper-container"]}>
                    <p className={styles["loading-text"]}>Loading...</p>
                  </div>
                </div>
              ) : isSearching && searchResults.length > 0 ? (
                <div className={styles["popup-wrapper"]}>
                  <div className={styles["popup-wrapper-container"]}>
                    <h6 className={styles["showing-results"]}>Showing {searchResults.length} results</h6>
                    <div className={styles["results-list-height"]}>
                      <ul className={styles["results-list"]}>
                        {searchResults.map(result => (
                          <li key={result.id} className={styles["results-content"]}>
                            <Link
                              href={`/vikatan-play/${result.slug}`}
                              onClick={() => setIsSearching(false)}
                              className={styles["results-content-sec"]}
                            >
                              <img
                                src={`https://media.vikatan.com/${result.thumbnail}`}
                                alt={result.name}
                                className={styles["result-image"]}
                              />
                              <div className={styles["results-content-details"]}>
                                <h4 className={styles["results-name"]}>{result.name}</h4>
                                <div className={styles["author-name-icon"]}>
                                  <Icon type="author" />
                                  <p className={styles["author-name"]}>{result.author_name_ta}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null} */}
              {loading ? (
                <div className={styles["popup-wrapper"]}>
                  <div className={styles["popup-wrapper-container"]}>
                    <p className={styles["loading-text"]}>
                      <img src={assetify(spinner)} className={styles["loader-image"]} />
                    </p>
                  </div>
                </div>
              ) : isSearching ? (
                <div className={styles["popup-wrapper"]} ref={menuRef}>
                  <div className={styles["popup-wrapper-container"]}>
                    {searchResults.length > 0 ? (
                      <div>
                        <h6 className={styles["showing-results"]}>Showing {searchResults.length} results</h6>
                        <div className={styles["results-list-height"]}>
                          <ul className={styles["results-list"]}>
                            {searchResults.map(result => (
                              <li key={result.id} className={styles["results-content"]}>
                                <Link
                                  href={`/vikatan-play/${result.slug}`}
                                  //  callback={() => setIsSearching(false)}
                                  className={styles["results-content-sec"]}
                                  callback={() => {
                                    handleNavigation(result);
                                  }}
                                >
                                  <img
                                    src={`https://media.vikatan.com/${result.thumbnail}`}
                                    alt={result.name}
                                    className={styles["result-image"]}
                                  />
                                  <div className={styles["results-content-details"]}>
                                    <h4 className={styles["results-name"]}>{result.name}</h4>
                                    <div className={styles["author-name-icon"]}>
                                      <Icon type="author" />
                                      <p className={styles["author-name"]}>{result.author_name_ta}</p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className={styles["no-results-text"]}>
                        <div>
                          <img
                            alt="Vikatan"
                            src={assetify(logo)}
                            className={styles["brand-logo"]}
                            width="104px"
                            height="65px"
                          />
                          <p>No results found</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          {IsOpened && <MobileLogin onClose={handleMobileLoginClose} />}
          <div className={styles["audio-header-cta"]}>
            {/* <div className={styles["trail-cta"]}>
              <a href="#">Get 30 Days Trial</a>
            </div> */}
            <div>
              <Link href="https://vikatanmobile.page.link/home-page">
                <div className={styles["install-appbar"]}>
                  Install app
                  <Icon type="icon-apple" className={styles["apple-icon"]} />
                  <Icon type="icon-google-play" />
                </div>
              </Link>
            </div>
            {loggedOut ? (
              <div className={styles["login-cta"]}>
                <a
                  onClick={loginOptionPopup}
                  // href={global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`}
                >
                  <Icon type="icon-login" />
                  <span className={styles["login-text"]}>Login</span>
                </a>
              </div>
            ) : (
              <div>
                {userType === "P" || userType === "T" || userType === "R" ? (
                  <div className={`${styles["game-btn-des"]}`}>
                    <Icon type={"icon-premium"} className={styles["premium-icon"]} />
                    <span className={styles["premium-text"]}>Premium</span>
                  </div>
                ) : (
                  <div className={`${styles["subscribe-btn-des"]}`}>
                    <a
                      href={`${domainName}/store?utm_source=direct_web&utm_medium=subscribe_button&utm_campaign=subscribe_button`}
                      className={styles["readapp-link"]}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      SUBSCRIBE
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isAudioMenuVisible && (
          <div ref={menuRef} className={`${styles["audio-header-popup"]} ${isAudioMenuVisible ? styles.open : ""}`}>
            <div>
              <div className={styles["audio-header-popup-header"]}>
                <a className={styles["home-link"]} aria-label="Vikatan" href="https://www.vikatan.com/">
                  <img
                    alt="Vikatan"
                    src={assetify(logo)}
                    className={styles["brand-logo"]}
                    width="240px"
                    height="50px"
                  />
                </a>
                <div className={styles["close-div"]} onClick={toggleMenu}>
                  X
                </div>
              </div>
              <ul className={styles["user-details"]}>
                <li>
                  <div>
                    {loggedOut ? (
                      <a
                        // href={global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`}
                        className={`${styles["trail-cta"]} ${styles["user-trail-cta"]}`}
                        onClick={loginOptionPopup}
                      >
                        <Icon type="icon-login" />
                        <span className={styles["user-cta-text"]}>Login</span>
                      </a>
                    ) : userType === "P" || userType === "T" || userType === "R" ? (
                      // <div className={`${styles["game-btn-des"]}`}>
                      //   <Icon type={"icon-premium"} className={styles["premium-icon"]} />
                      //   <span className={styles["premium-text"]}>Premium</span>
                      // </div>
                      <div />
                    ) : (
                      <div className={`${styles["subscribe-btn-des"]}`}>
                        <a
                          href={`${domainName}/store?utm_source=direct_web&utm_medium=subscribe_button&utm_campaign=subscribe_button`}
                          className={styles["readapp-link"]}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          SUBSCRIBE
                        </a>
                      </div>
                    )}
                  </div>
                </li>

                {userEmail && (
                  <li>
                    <strong>User’s email</strong>
                    <p>{userEmail}</p>
                  </li>
                )}
                {/* <li>
                  <strong>Phone number</strong>
                  <p>9876543210</p>
                </li>
                <li>
                  <strong>Subscription pack name</strong>
                  <p>All Magazine</p>
                </li>
                <li>
                  <strong>Sub start date</strong>
                  <p>JAN 20 2024</p>
                </li>
                <li>
                  <strong>End date</strong>
                  <p>May 20 2026</p>
                </li> */}
                <li>
                  <strong>Contact us</strong>
                  <p>
                    <a href="mailto:webmaster@vikatan.com">webmaster@vikatan.com</a>
                  </p>
                </li>
              </ul>
            </div>
            <div className={styles["audio-footer"]}>
              <div className={styles["play-store-icons"]}>
                <a href="https://play.google.com/store/apps/details?id=com.vikatanapp&hl=en&mt=8">
                  <Icon type="icon-audio-play-store" />
                </a>
                <a href="https://apps.apple.com/in/app/vikatan/id452698974">
                  <Icon type="icon-audio-app-store" />
                </a>
              </div>
              <p>© vikatan 2025. All Rights Reserved</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { user: get(state, ["user"]) };
}

export default connect(mapStateToProps)(VikatanAudioMagazineHeader);
