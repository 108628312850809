import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import MegaMenu from "./megamenu";
import NavMenubar from "./nav-menubar";
import HeaderBrandRow from "./header-brand-row";
import styles from "./styles.m.css";
import FullWidthSearch from "../../rows/header/full-width-search";
import { LoadingIndicatorComponent } from "../../atoms/loading-indicator";
import { checkAppSlug } from "../../utils/utils";
import IframeModal from "./iframe-modal";
import { throttle } from "lodash";
import SubscriptionLink from "./subscription-link";
import { WithHostUrl } from "@quintype/components";
import SecondaryMenu from "../../rows/header/secondary-menubar";
import Cookies from "universal-cookie";
import { fetchOneTabLogin } from "../../../api";
import { Vikataninterstitial } from "../ad-free";
import AudioMagazinePlayer from "../../pages/story/audiomagazine-story";
import { DeepBI } from "../../../integrations";
import GoogleAccountsInitializer from "./user-login/GoogleAccountsInitializer";

let prevScrollpos = null;
const cookies = new Cookies();

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMegaMenuOpen: false,
      isSearchOpen: false,
      loginData: {},
      subscriptionLink: props.subscriptionLink,
      showPromo: true
    };
    this.onMegaMenuToggle = this.onMegaMenuToggle.bind(this);
    this.onSearchToggle = this.onSearchToggle.bind(this);
    this.handleEscapeKeyPress = this.handleEscapeKeyPress.bind(this);
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
    this.searchRef = null;
    this.searchButtonDesktopRef = null;
    global.handleCredentialResponse = this.handleCredentialResponse;
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEscapeKeyPress);
    window.addEventListener("click", this.handleGlobalClick);
    window.addEventListener("scroll", throttle(this.handleScroll, 100));
    global.userPromise.then(this.props.addUser);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscapeKeyPress);
    window.removeEventListener("click", this.handleGlobalClick);
    window.removeEventListener("scroll", this.handleScroll);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pageLoading && state.isMegaMenuOpen) {
      return { isMegaMenuOpen: false };
    }

    return null;
  }

  handleGlobalClick(event) {
    if (
      this.searchRef &&
      !this.searchRef.contains(event.target) &&
      this.searchButtonDesktopRef &&
      !this.searchButtonDesktopRef.contains(event.target) &&
      this.searchButtonMobileRef &&
      !this.searchButtonMobileRef.contains(event.target)
    ) {
      this.setState({ isSearchOpen: false });
    }
  }

  handleEscapeKeyPress(event) {
    if (event.key === "Escape" || event.keyCode === 27) {
      if (this.state.isMegaMenuOpen) {
        this.setState({ isMegaMenuOpen: false });
      }
      if (this.state.isSearchOpen) {
        this.setState({ isSearchOpen: false });
      }
    }
  }

  handleScroll = () => {
    var currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
    document.getElementById("onScrollhide").classList.add("moveContentUp");
    if (currentScrollPos > prevScrollpos) {
      document.getElementById("onScrollhide").classList.add("moveContentDown");
      document.getElementById("onScrollhide").classList.remove("moveContentUp");
    } else {
      document.getElementById("onScrollhide").classList.remove("moveContentDown");
      document.getElementById("onScrollhide").classList.add("moveContentUp");
    }

    prevScrollpos = currentScrollPos <= 0 ? 0 : currentScrollPos;
  };

  onMegaMenuToggle(event) {
    this.setState(
      function(prevState) {
        return {
          isMegaMenuOpen: !prevState.isMegaMenuOpen,
          isSearchOpen: false
        };
      },
      () => {
        if (this.state.isMegaMenuOpen) {
          window.location.hash = "header";
        } else {
          window.location.hash = "";
        }
      }
    );
    if (event instanceof global.Event) {
      event.stopPropagation();
    }
  }

  onSearchToggle(event) {
    this.setState({
      isSearchOpen: !this.state.isSearchOpen,
      isMegaMenuOpen: false
    });

    if (event instanceof global.Event) {
      event.stopPropagation();
    }
  }

  handleCredentialResponse = response => {
    fetchOneTabLogin(response.credential)
      .then(response => {
        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);
        const resData = get(response, ["data"]);
        const resVuid = get(response, ["vuid"]);
        const email = get(response, ["data", "email"]);
        const guid = get(response, ["data", "guid"]);
        const evSS = get(response, ["data", "mainSession", "sessionId"]);
        const exSS = get(response, ["data", "secureSession", "sessionId"]);
        const hostname = window.location.hostname.replace(/^www\./, "");
        const domain =
          hostname.includes(".vikatan.com") || hostname === "vikatan.com" ? ".vikatan.com" : `.${hostname}`;
        cookies.set("ev-guid", guid, { path: "/", domain: domain, expires: nextYear });
        cookies.set("ev_ss", evSS, { path: "/", domain: domain, expires: nextYear });
        cookies.set("ex_ss", exSS, { path: "/", domain: domain, expires: nextYear });
        cookies.set("vuid", resVuid, { path: "/", domain: domain, expires: nextYear });
        global.localStorage.setItem("evolok:ev_session", JSON.stringify(resData));
        DeepBI.PingLoginevents("onetab_user_login", "success", email, resData);
        global.location.reload(false);
      })
      .catch(console.error);
  };

  render() {
    const { menuGroup, socialLinks, appStoreLinks, latestIssues, user, secondaryMenu, apiKey } = this.props;
    const loggedOutStat = get(user, ["loggedOut"], null);

    const { subscriptionLink } = this.state;
    if (checkAppSlug()) {
      return false;
    }
    return (
      <React.Fragment>
        {loggedOutStat === true ? (
          <GoogleAccountsInitializer loggedOut={loggedOutStat} onCredentialResponse={this.handleCredentialResponse} />
        ) : (
          <div />
        )}
        <LoadingIndicatorComponent />
        <Vikataninterstitial />
        <AudioMagazinePlayer />
        {!loggedOutStat && <IframeModal user={user} />}
        <div id="we-headerad" />
        <header className={styles["header"]}>
          <HeaderBrandRow
            user={this.props.user}
            appStoreLinks={appStoreLinks}
            latestIssues={latestIssues}
            onMegaMenuToggle={this.onMegaMenuToggle}
            isMegaMenuOpen={this.state.isMegaMenuOpen}
            subscriptionLink={subscriptionLink}
            onSearchToggle={this.onSearchToggle}
            isSearchOpen={this.state.isSearchOpen}
            vikatanHosts={this.props.vikatanHosts}
            apiKey={apiKey}
            searchButtonRef={ref => {
              this.searchButtonMobileRef = ref;
            }}
          />
          <div id="onScrollhide">
            <div className="hidden-menubar">
              <NavMenubar
                menuGroup={menuGroup}
                socialLinks={socialLinks}
                onMegaMenuToggle={this.onMegaMenuToggle}
                isMegaMenuOpen={this.state.isMegaMenuOpen}
                onSearchToggle={this.onSearchToggle}
                isSearchOpen={this.state.isSearchOpen}
                searchButtonRef={ref => {
                  this.searchButtonDesktopRef = ref;
                }}
              />
              <SecondaryMenu secondaryMenu={secondaryMenu} />
            </div>
            <div className="subscribebar-top">
              {this.state.showPromo && (
                <div className={`${styles["subscribe-wrapper"]}`}>
                  <div className={`${styles["subscribe-grid"]} container`}>
                    {
                      <WithHostUrl>
                        {({ primaryHostUrl }) =>
                          primaryHostUrl && subscriptionLink && !primaryHostUrl.match(/^https?:\/\/www.vikatan.tv/) ? (
                            <SubscriptionLink href={subscriptionLink.href} text={"Subscribe Today"} />
                          ) : (
                            <SubscriptionLink href={"#"} text={"Subscribe Today"} />
                          )
                        }
                      </WithHostUrl>
                    }
                    <div className={styles["close-wrapper"]}>
                      <span
                        className={styles["close-wrapper-icon"]}
                        onClick={() => {
                          this.setState({ showPromo: false });
                        }}
                      >
                        x
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <FullWidthSearch
            searchRef={ref => {
              this.searchRef = ref;
            }}
            isOpen={this.state.isSearchOpen}
            buttonClick={this.onSearchToggle}
          />

          {this.state.isMegaMenuOpen ? (
            <React.Fragment>
              <MegaMenu
                className={styles["megamenu-overlay"]}
                menuGroup={menuGroup}
                socialLinks={socialLinks}
                appStoreLinks={appStoreLinks}
                latestIssues={latestIssues}
                subscriptionLink={subscriptionLink}
              />
              <style>{":root{--body-height: 100vh;} body{overflow:hidden;}"}</style>
            </React.Fragment>
          ) : null}
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  menuGroup: MegaMenu.propTypes.menuGroup,
  secondaryMenu: MegaMenu.propTypes.secondaryMenu,
  appStoreLinks: MegaMenu.propTypes.appStoreLinks,
  socialLinks: MegaMenu.propTypes.socialLinks,
  latestIssues: MegaMenu.propTypes.latestIssues,
  subscriptionLink: HeaderBrandRow.propTypes.subscriptionLink,
  pageLoading: PropTypes.bool,
  addUser: PropTypes.func,
  user: PropTypes.object,
  apiKey: PropTypes.string,
  vikatanHosts: HeaderBrandRow.propTypes.vikatanHosts
};

function mapStateToProps(state) {
  const data = get(state, ["qt", "data", "header"]);
  const pageLoading = get(state, ["pageLoading"], false);
  const user = get(state, ["user"]);
  const vikatanHosts = get(state, ["qt", "config", "vikatanHosts"]);
  return data ? { ...data, pageLoading, user, vikatanHosts } : {};
}

function mapDispatchToProps(dispatch) {
  // move this to app.js maybe
  return {
    addUser: function(user) {
      dispatch({
        type: "ADD_USER",
        user
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
