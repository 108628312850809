import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import { Link, WithHostUrl } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../../assets/images/vikatan-logo.png";
import logoicon from "../../../../../assets/images/vikatan-icon.png";
import coins from "../../../../../assets/images/coin.png";
import LatestIssues from "../latest-issues";
import AppLinks from "../app-links";
import UserLogin from "../user-login";
import { VikatanHeaderAd } from "../../ad-free";
import { GA, DeepBI } from "../../../../integrations";
import Cookies from "universal-cookie";
import Icon from "../../../atoms/icon";
import VikatanCoins from "../../../rows/header/vikatan-coins/index.js";
import { fetchCoinsCookie } from "../../../../../isomorphic/api";
import { EncryptData } from "../../../../../isomorphic/components/utils/utils";
import MobileLogin from "../user-login/login-mobile.js";

const cookies = new Cookies();
let vuid = cookies.get("vuid");
const HeaderBrandRow = ({
  appStoreLinks,
  onMegaMenuToggle,
  isMegaMenuOpen,
  subscriptionLink,
  onSearchToggle,
  user,
  vikatanHosts,
  searchButtonRef,
  apiKey
}) => {
  const [userType, setUserType] = useState("F");
  const [User, setUser] = useState(false);
  const [EnableCoins, SetEnableCoins] = useState(false);
  const [Showpopup, setShowpopup] = useState(false);
  const [IsOpened, setIsOpened] = useState(false);
  const [subscripeUrl, setSubscripeUrl] = useState("");

  // const domainName = global && global.location && global.location.origin;

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      user && setUserType(user.userType);
      const hasVisitedToday = cookies.get("visitedToday");

      if (vuid) {
        if (!hasVisitedToday) {
          // Set the cookie for the first visit today
          const currentDate = new Date();
          const expirationDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate() + 1, // Set to tomorrow
            0, // Hour
            0, // Minute
            0 // Second
          );

          const secretKey = apiKey || "";
          const payload = {};
          payload.action = "vikatan_login";
          payload.uid = vuid;

          let EncryptedValue = EncryptData(secretKey, JSON.stringify(payload));
          const sendata = { data: EncryptedValue };
          fetchCoinsCookie(sendata);
          // Set the cookie
          cookies.set("visitedToday", "true", { expires: expirationDate });
        }
      }
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      let domain = global && global.location && global.location.hostname;
      if (domain === "cinema.vikatan.com" || domain === "sports.vikatan.com") {
        domain = "www.vikatan.com";
      }
      const host = `https://${domain}/store?utm_source=direct_web&utm_medium=subscribe_button_mob&utm_campaign=subscribe_button_mob`;
      SetEnableCoins(true);

      setSubscripeUrl(host);
      global.userPromise.catch(() => ({})).then(user => {
        if (Object.keys(user).length !== 0 && user.loggedOut === false) {
          user && setUser(user);
        }
      });
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const showpopup = () => {
    setShowpopup(!Showpopup);
    GA.customEvent("headerCoins", "coinClick", "click");
  };

  const loginOptionPopup = () => {
    DeepBI.PingLoginevents("Login_widget_view");
    if (global.webengage && global.webengage.track) {
      global.webengage.track("Login_widget_view", {});
    }
    setShowpopup(false);
    setIsOpened(prevIsOpened => !prevIsOpened);
  };

  const handleMobileLoginClose = isPopupClosed => {
    setIsOpened(isPopupClosed);
  };

  return (
    <div className={styles["brand-row"]}>
      <div className={`${styles["brand-row-container"]} container`}>
        <div className={`${styles["brand-row-inner-wrap"]}`}>
          {/* <button
            aria-label="menu toggle"
            role="button"
            onClick={e => {
              onMegaMenuToggle(e);
            }}
            className={`${styles["hamburger"]} ${isMegaMenuOpen ? styles["is-open"] : ""} hidden-desktop`}
          >
            <span className={styles["line"]} />
            <span className={styles["line"]} />
            <span className={styles["line"]} />
          </button> */}

          <WithHostUrl>
            {({ primaryHostUrl }) => (
              <Link
                className={styles["home-link"]}
                aria-label="Vikatan"
                href={primaryHostUrl ? primaryHostUrl + "/" : "/"}
              >
                <img alt="Vikatan" src={assetify(logo)} className={styles["brand-logo"]} width="240px" height="50px" />
                <img
                  alt="Vikatan"
                  src={assetify(logoicon)}
                  className={styles["brand-logo-icon"]}
                  width="40px"
                  height="50px"
                />
              </Link>
            )}
          </WithHostUrl>
          <div className={styles["header-right-panel"]}>
            <div className={`${styles["header-ad"]} hidden-mobile`}>
              <div id="homemlb_we">
                <VikatanHeaderAd adtype="Vikatan_Desktop_AP_TOP_420x70" placement="all" hidetitle={true} />
              </div>
            </div>
            <div className={styles["header-right"]}>
              <div className={styles["brand-row-right"]}>
                {EnableCoins && EnableCoins === true ? (
                  <div>
                    {User && User ? (
                      <div className={styles["header-coins"]}>
                        <img alt="Vikatan" src={assetify(coins)} className={styles["coin-img"]} />
                        <Link
                          className={styles["home-link"]}
                          aria-label="Vikatan"
                          href={"https://login.vikatan.com/wallet/?pfrom=header-coins"}
                        >
                          <span>
                            {" "}
                            <VikatanCoins />
                          </span>
                        </Link>
                      </div>
                    ) : (
                      <div
                        className={`${styles["header-coins-cookie"]} ${styles["header-coins"]}`}
                        onClick={() => {
                          showpopup();
                        }}
                      >
                        <img alt="Vikatan" src={assetify(coins)} className={styles["coin-img"]} />
                        <span>
                          {" "}
                          <VikatanCoins />
                        </span>
                      </div>
                    )}
                  </div>
                ) : null}
                {Showpopup === true ? (
                  <div className={`${styles["coin-popup-wrapper"]}`}>
                    <div className={`${styles["coin-popup-div"]}`}>
                      <div className={`${styles["coin-popup-header"]}`}>
                        <div
                          className={`${styles["close-div"]}`}
                          onClick={() => {
                            showpopup();
                          }}
                        >
                          X
                        </div>
                        <img
                          src="https://media.vikatan.com/vikatan/2022-11/2a05fd60-1aee-47ff-bc6d-bcee9e7f6748/coins_header.png"
                          alt=""
                        />
                      </div>
                      <div className={`${styles["coin-popup-content"]}`}>
                        <h5>
                          Register to <span className={styles["text-red"]}>Get Free Vikatan</span> Coins
                        </h5>
                        <p>
                          இன்றே விகடன்.காமில் Register செய்து உங்களுக்கான
                          <span className={`${styles["coin-count"]}`}>100</span> விகடன் காயின்களை இலவசமாகப்
                          பெற்றிடுங்கள்.
                        </p>
                        <a
                          // href={
                          //   global.location && `${"https://login.vikatan.com"}?redirect_url=${global.location.href}`
                          // }
                          className={`${styles["cta-btn"]}`}
                          onClick={loginOptionPopup}
                        >
                          Register
                        </a>
                        <div>
                          <p className={styles["premium-para"]}>
                            Have an account already?{" "}
                            <a
                              // href={
                              //   global.location &&
                              //   `${"https://login.vikatan.com"}?redirect_url=${
                              //     global.location.href
                              //   }?pfrom=header-coins-login`
                              // }
                              className={styles["btn-link"]}
                              onClick={loginOptionPopup}
                            >
                              Log in
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {IsOpened && <MobileLogin onClose={handleMobileLoginClose} />}

                <div className={`${styles["read-app"]} hidden-desktop`}>
                  {userType === "P" || userType === "T" || userType === "R" ? (
                    <div className={`${styles["game-btn-des"]}`}>
                      <Icon type={"icon-premium"} className={styles["premium-icon"]} />
                      <span className={styles["premium-text"]}>Premium</span>
                    </div>
                  ) : (
                    <div className={`${styles["subscribe-btn-des"]}`}>
                      <a href={subscripeUrl} className={styles["readapp-link"]} rel="noopener noreferrer">
                        SUBSCRIBE
                      </a>
                    </div>
                  )}
                </div>
                <div className={`${styles["read-app"]} hidden-mobile`}>
                  {userType === "P" || userType === "T" || userType === "R" ? (
                    <div className={`${styles["game-btn-des"]}`}>
                      <Icon type={"icon-premium"} className={styles["premium-icon"]} />
                      <span className={styles["premium-text"]}>Premium</span>
                    </div>
                  ) : (
                    <div className={`${styles["subscribe-btn-des"]}`}>
                      <a
                        href={subscripeUrl}
                        className={styles["readapp-link"]}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        SUBSCRIBE
                      </a>
                    </div>
                  )}
                </div>

                {/* <div className="hidden-mobile">
                {appStoreLinks && <AppLinks appStoreLinks={appStoreLinks} className="flex-show-tablet-and-desktop" />}
              </div> */}

                <WithHostUrl>
                  {({ primaryHostUrl }) =>
                    primaryHostUrl && !primaryHostUrl.match(/^https?:\/\/www.vikatan.tv/) ? (
                      <UserLogin user={user} vikatanHosts={vikatanHosts} />
                    ) : (
                      <UserLogin user={user} vikatanHosts={vikatanHosts} />
                    )
                  }
                </WithHostUrl>

                <div
                  aria-label="menu toggle"
                  role="button"
                  onClick={e => {
                    onMegaMenuToggle(e);
                  }}
                  className={`${styles["hamburger"]} ${isMegaMenuOpen ? styles["is-open"] : ""} hidden-desktop`}
                >
                  <Icon type="menu-search" className={styles["menu-search"]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderBrandRow.propTypes = {
  searchButtonRef: PropTypes.func,
  appStoreLinks: AppLinks.propTypes.appStoreLinks,
  latestIssues: LatestIssues.propTypes.latestIssues,
  onMegaMenuToggle: PropTypes.func,
  isMegaMenuOpen: PropTypes.bool,
  onSearchToggle: PropTypes.func,
  apiKey: PropTypes.string,
  subscriptionLink: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string
  }),
  user: PropTypes.object,
  vikatanHosts: PropTypes.shape({
    login: PropTypes.string,
    subscription: PropTypes.string,
    userProfile: PropTypes.string,
    logout: PropTypes.string
  })
};

export default HeaderBrandRow;
