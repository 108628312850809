import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

import styles from "./styles.m.css";

const cookies = new Cookies();
const coStat = cookies.get("Cookie_Status");

const Delayed = ({ children, waitBeforeShow = 800 }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(
    () => {
      setTimeout(() => {
        setIsShown(true);
      }, waitBeforeShow);
    },
    [waitBeforeShow]
  );

  return isShown ? children : null;
};

const CookieConsent = () => {
  const [state, setstate] = useState(false);

  if (coStat || state) return false;

  const handleCookiePolicy = () => {
    const current = new Date();
    const nextWeek = new Date();
    const hostname = global && global.location.hostname.replace(/^www\./, "");
    const domain = hostname.includes(".vikatan.com") || hostname === "vikatan.com" ? ".vikatan.com" : `.${hostname}`;
    nextWeek.setFullYear(current.getFullYear() + 2);
    cookies.set("Cookie_Status", true, {
      expires: nextWeek,
      domain: domain,
      path: "/"
    });
    setstate(true);
  };

  const domainName = global && global.location && global.location.origin;

  return (
    <Delayed>
      <div className={styles["cookieConsent"]}>
        <div className={styles["text-capitalize"]}>
          <p>
            விகடனின் பிரைவசி மற்றும் குக்கீ பாலிசிகளை ஏற்பதன் மூலம் உங்களுக்கு இத்தளத்தில் நிறைவான அனுபவம் கிடைப்பதை
            உறுதி செய்ய உதவுங்கள். நன்றி!
            <a href={`${domainName}/privacy-policy-web`}>
              <strong> பிரைவசி </strong>
            </a>
            <span>மற்றும்</span>
            <a href={`${domainName}/privacy-policy-web`}>
              <strong> குக்கீ பாலிசி</strong>
            </a>
          </p>
        </div>
        <button className={styles["btn-acc"]} onClick={() => handleCookiePolicy()}>
          I Accept
        </button>
      </div>
    </Delayed>
  );
};

export default CookieConsent;
