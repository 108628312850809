import React from "react";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";

const VikatanAudioMagazineFooter = () => {
  return (
    <div className={styles["audio-footer"]}>
      <div className={`${styles["audio-container"]}`}>
        <div className={`${styles["audio-footer-grid"]}`}>
          <div>
            <div className={styles["audio-mail-phone"]}>
              <p>
                <strong>Email:</strong> <a href="mailto:webmaster@vikatan.com">webmaster@vikatan.com</a>
              </p>
              <p>
                <strong>Phone:</strong> <a href="tel:044-66802939">044-66802939</a> (Mon - Fri)
              </p>
            </div>
            <div className={`${styles["play-store-icons"]} hidden-mobile`}>
              <a href="https://apps.apple.com/in/app/vikatan/id452698974" target="_blank" rel="noopener noreferrer">
                <Icon type="icon-audio-app-store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.vikatanapp&hl=en&mt=8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon type="icon-audio-play-store" />
              </a>
            </div>
          </div>
          <div className={styles["footer-menus"]}>
            <ul>
              <li>
                <a href="https://www.vikatan.com/">Vikatan.com</a>
              </li>
              <li>
                <a href="https://www.vikatan.com/store">Digital Subscription</a>
              </li>
              <li>
                <a href="https://store.vikatan.com/ebook/">Ebooks</a>
              </li>
              <li>
                <a href="https://books.vikatan.com/">Print Books</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="https://www.vikatan.com/about-us-web">About us</a>
              </li>
              <li>
                <a href="https://www.vikatan.com/contact-us-web">Contact us</a>
              </li>
              <li>
                <a href="https://www.vikatan.com/terms-and-conditions-web">Terms & conditions</a>
              </li>
            </ul>
            <ul>
              <li>Follow US</li>
              <li>
                <a href="https://www.facebook.com/vikatanweb">
                  <Icon type="icon-audio-fb" />
                </a>
                <a href="https://x.com/vikatan">
                  <Icon type="icon-audio-twitter" />
                </a>
                <a href="https://www.instagram.com/vikatan_emagazine/?hl=en">
                  <Icon type="icon-audio-instagram" />
                </a>
                <a href="https://www.whatsapp.com/channel/0029Va7F0Hj0bIdoYCCkqs41">
                  <Icon type="icon-audio-whatsapp" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles["play-store-icons"]} hidden-desktop`}>
          <a href="#">
            <Icon type="icon-audio-app-store" />
          </a>
          <a href="#">
            <Icon type="icon-audio-play-store" />
          </a>
        </div>
        <p className={styles["audio-footer-copyright"]}>© vikatan 2025. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default VikatanAudioMagazineFooter;
