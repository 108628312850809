import React, { useEffect, useRef } from "react";
import styles from "./styles.m.css";
import { fetchGenerateOtp } from "../../../../api";
import Cookies from "universal-cookie";
import { get } from "lodash";
import { DeepBI } from "../../../../integrations";
import PropTypes from "prop-types";
import { bin2hex, intoVal } from "../../../utils/utils";

const cookies = new Cookies();

const GmailSignin = ({
  setIsloading,
  setErrorWithTimeout,
  setShowEmailMobileInput,
  setShowSuccessLogin,
  generateJWT
}) => {
  const googleSignInButtonRef = useRef();

  useEffect(() => {
    let executed = false;

    const onLoadGoogleCallback = () => {
      if (!executed) {
        executed = true;
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/platform.js?onload=onLoadGoogleCallback";
        document.body.appendChild(script);
      }
    };

    window.onLoadGoogleCallback = () => {
      window.gapi.load("auth2", () => {
        window.auth2 = window.gapi.auth2.init({
          client_id: "962641865525-qk3eqej1shfi589ahq1enclp2i5soen0.apps.googleusercontent.com",
          scope: "email" // Specify required scopes here
        });
        window.auth2.attachClickHandler(googleSignInButtonRef.current, {}, async function(googleUser) {
          setIsloading(true);

          const profile = googleUser.getBasicProfile();
          const email = profile.getEmail();
          const authResponse = googleUser.getAuthResponse();
          const accessToken = authResponse.access_token;

          const payload = {
            email: email, // This ensures dynamic mobile number
            token: accessToken
          };

          const body = generateJWT(payload);

          try {
            const response = await fetchGenerateOtp(body, "social-info");
            if (response.status === 1) {
              setIsloading(false);
              setShowEmailMobileInput(false);
              setShowSuccessLogin(true);
              const current = new Date();
              const nextYear = new Date();
              nextYear.setFullYear(current.getFullYear() + 1);
              const resData = get(response, ["data"]);
              const guid = get(response, ["data", "guid"]);
              const VuID = get(response, ["data", "vuid"]);
              const email = get(response, ["data", "email"]);
              const userType = get(response, ["data", "userType"]);
              const evSS = get(response, ["data", "mainSession", "sessionId"]);
              const exSS = get(response, ["data", "secureSession", "sessionId"]);
              const hostname = window.location.hostname.replace(/^www\./, "");
              const domain =
                hostname.includes(".vikatan.com") || hostname === "vikatan.com" ? ".vikatan.com" : `.${hostname}`;
              cookies.set("ev-guid", guid, { path: "/", domain: domain, expires: nextYear });
              cookies.set("ev_ss", evSS, { path: "/", domain: domain, expires: nextYear });
              cookies.set("ex_ss", exSS, { path: "/", domain: domain, expires: nextYear });
              cookies.set("vuid", VuID, { path: "/", domain: domain, expires: nextYear });
              cookies.set("vikatan[auth_id]", bin2hex(email), { path: "/", domain: domain, expires: nextYear });
              cookies.set("vikatan[utype]", userType, { path: "/", domain: domain, expires: nextYear });

              global.localStorage.setItem("evolok:ev_session", JSON.stringify(resData));
              DeepBI.PingLoginevents("Google_verification", "success", email, resData);
              DeepBI.PingLoginevents("user_login", "success", email, resData);

              const userId = intoVal(VuID);
              if (global.webengage) {
                global.webengage.user.setAttribute("User_Id", userId);
                global.webengage.user.login(userId);
              }
              const params = new URLSearchParams(window.location.search);
              const redirectUrl = params.get("redirect_url");

              if (redirectUrl) {
                // Redirect to the specified URL after a 2-second delay
                setTimeout(() => {
                  global.location.href = redirectUrl;
                }, 1000);
              } else {
                // Reload the page after a 2-second delay
                setTimeout(() => {
                  global && global.location.reload();
                }, 1000);
              }
            } else {
              setIsloading(false);
              DeepBI.PingLoginevents("Google_verification", "failure", "", null, response.msg);
              setErrorWithTimeout(response.msg);
              DeepBI.PingLoginevents("user_login", "failure");
            }
          } catch (error) {
            console.error("Google login error:", error);
            setIsloading(false);

            DeepBI.PingLoginevents("Google_verification", "failure", "", null);

            // You may want to display an error message to the user here
          }

          // Your logic for handling the authenticated Google user goes here
        });
        // }
      });
    };

    // Load Google API script
    onLoadGoogleCallback();

    // Cleanup
    return () => {
      delete window.onLoadGoogleCallback;
      delete window.auth2;
    };
  }, []);

  const handleButtonClick = () => {
    DeepBI.PingLoginevents("Click_Google");
  };

  return (
    <div className={styles["button-outline"]} ref={googleSignInButtonRef} onClick={handleButtonClick}>
      <div className={styles["google-login-text"]}>
        <img
          src="https://gumlet.assettype.com/vikatan/2023-07/9f594ca3-1b99-4563-8e14-d196d56ab3a2/login_google.png"
          width={22}
          height={22}
        />
        {/* Your Google Sign-In button */}
        <button className={styles["google-login"]}>
          <span>Continue with Google</span>
        </button>
      </div>
    </div>
  );
};

// PropTypes validation
GmailSignin.propTypes = {
  setIsloading: PropTypes.func, // Ensure setIsloading is a function
  setErrorWithTimeout: PropTypes.func, // Ensure setErrorWithTimeout is a function,
  setShowEmailMobileInput: PropTypes.func,
  setShowSuccessLogin: PropTypes.func,
  generateJWT: PropTypes.func
};

export default GmailSignin;
