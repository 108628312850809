import { get } from "lodash";
import { renderIsomorphicComponent, renderComponent } from "@quintype/framework/client/start";
import { pickComponent } from "../isomorphic/pick-component";
import Footer from "../../app/isomorphic/components/rows/footer";
import EmptyFooter from "../isomorphic/components/rows/footer/emptyFooter";
import Header from "../../app/isomorphic/components/rows/header";
import EmptyHeader from "../../app/isomorphic/components/rows/header/emptyHeader";
import VikatanDealsHeader from "../../app/isomorphic/components/rows/vikatan-deals-header";
import VikatanDealsFooter from "../../app/isomorphic/components/rows/vikatan-deals-footer";
import PDFHeader from "../../app/isomorphic/components/rows/vikatan-pdf-header";
import VikatanAudioMagazineHeader from "../isomorphic/components/rows/vikatan-audio-magazine-header";
import VikatanAudioMagazineFooter from "../isomorphic/components/rows/vikatan-audio-magazine-footer";
import VikatanLoginHeader from "../isomorphic/components/rows/vikatan-login-header";

export function preRenderApplication(store) {
  const pageType = get(store.getState(), ["qt", "currentPath"], "");
  if (pageType === "/short-news") {
    renderComponent(EmptyHeader, "header", store);
  } else if (pageType.split("/")[1] === "vikatan-deals") {
    renderComponent(VikatanDealsHeader, "header", store);
  } else if (pageType.split("/")[1] === "pdf-preview") {
    renderComponent(PDFHeader, "header", store);
  } else if (pageType.split("/")[1] === "audio-book") {
    renderComponent(VikatanAudioMagazineHeader, "header", store);
  } else if (pageType.split("/")[2] === "icc-world-cup-2023") {
    renderComponent(EmptyHeader, "header", store);
  } else if (pageType.split("/")[2] === "ipl") {
    renderComponent(EmptyHeader, "header", store);
  } else if (pageType.split("/")[2] === "icc-t20-world-cup") {
    renderComponent(EmptyHeader, "header", store);
  } else if (pageType.split("/")[1] === "olympics") {
    renderComponent(EmptyHeader, "header", store);
  } else if (pageType.split("/")[1] === "vikatan-play") {
    renderComponent(VikatanAudioMagazineHeader, "header", store);
  } else if (pageType.split("/")[1] === "vikatan-play-story") {
    renderComponent(VikatanAudioMagazineHeader, "header", store);
  } else if (pageType.split("/")[1] === "user") {
    renderComponent(VikatanLoginHeader, "header", store);
  } else if (pageType.split("/")[2] === "champions-trophy-2025") {
    renderComponent(EmptyHeader, "header", store);
  } else if (pageType.split("/")[1] === "vikatan-play-search") {
    renderComponent(VikatanAudioMagazineHeader, "header", store);
  } else if (pageType.split("/")[2] === "ipl-2025") {
    renderComponent(EmptyHeader, "header", store);
  } else {
    renderComponent(Header, "header", store);
  }

  if (pageType === "/short-news") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[1] === "vikatan-deals") {
    renderComponent(VikatanDealsFooter, "footer", store);
  } else if (pageType.split("/")[1] === "pdf-preview") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[2] === "icc-world-cup-2023") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[2] === "ipl") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[1] === "audio-book") {
    renderComponent(VikatanAudioMagazineFooter, "footer", store);
  } else if (pageType.split("/")[1] === "olympics") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[1] === "vikatan-play") {
    renderComponent(VikatanAudioMagazineFooter, "footer", store);
  } else if (pageType.split("/")[2] === "champions-trophy-2025") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[1] === "user") {
    renderComponent(EmptyFooter, "footer", store);
  } else if (pageType.split("/")[1] === "vikatan-play-search") {
    renderComponent(VikatanAudioMagazineFooter, "footer", store);
  } else if (pageType.split("/")[2] === "ipl-2025") {
    renderComponent(EmptyFooter, "footer", store);
  } else {
    renderComponent(Footer, "footer", store);
  }
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  global.qtConfig = store && store.getState().qt.config;
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}
